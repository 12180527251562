import { LoginPage } from '@fluentsolar/lib-stats/page-LoginPage/LoginPage';
import * as React from 'react';

const Login = () => {
  return (
    <div className="w-full min-h-screen theme-general theme-dark bg-primary text-normal">
      <LoginPage onSuccessRoute="/dashboard/profile" app="STATS-SITE" />
    </div>
  );
};

export default Login;

// trigger
